import React from 'react'; 
import '../style/writing.css'; 

function WritingsContent() {
  return (
      <div className="writings-content">
          <h2>Writings</h2>

          <h3 className='topic'>Stablecoin payment</h3> 
          <div className="writing-item">
              <p className="date">10.21.2024 - <a href="https://nicopeieth.substack.com/p/why-stripe-acquired-bridge-for-11b?r=15ka7e">Why Stripe acquired Bridge for 1.1 Billion</a></p>
              <p className="date">10.09.2024 - <a href="https://nicopeieth.substack.com/p/what-stripe-has-done-wrong-with-their?r=15ka7e">What Stripe Has Done Wrong with Their Crypto Payment Solution</a></p>  
          </div>

          <h3 className='topic'>Blockchain infrastructure</h3>
          <div className="writing-item">  
              <p className="date">12.09.2022 - <a href="https://nicopeieth.substack.com/p/general-purpose-vs-app-specific-guide?r=15ka7e">General-Purpose vs App-Specific Guide for Application Developers</a> </p> 
          </div>

          <br />  
          <br />  
          <br />  
          <br />  
          <br />   
          <a href="https://nicopeieth.substack.com/" className="more-link">More</a>
      </div>
  );
}

export default WritingsContent;

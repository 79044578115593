import React, { useState, useEffect } from 'react'; 
function AboutContent({ toggleColor }) {
    // Store image paths and captions in an array
    const images = [
      { src: process.env.PUBLIC_URL + '/1.png', caption: "What If? by Six N. Five" }, 
      { src: process.env.PUBLIC_URL + '/2.png', caption: "What If? by Six N. Five" }, 
      { src: process.env.PUBLIC_URL + '/3.png', caption: "What If? by Six N. Five" }
    ];
  
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    // Rotate images every 3 seconds
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2000); // Change image every 3 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [images.length]);
  
    return (
      <div className="container">
        {/* Display the rotating image */}
        <img src={images[currentImageIndex].src} className="top-left-image" alt="Rotating Image" />
        {/* Display the caption as a hyperlink */}
        <div className="image-caption">
          <a href="https://sixnfive.com/projects/what-if" target="_blank" rel="noopener noreferrer">
            {images[currentImageIndex].caption}
          </a>
        </div>
  
        <div className="content">
          <p className="intro">
            <br />
            Hello, I'm <strong>Nico</strong>.<br />
            <br /> I build, research, write, and invest in frontier crypto applications and infrastructure systems. <br />
            <br /> Currently building <a href="https://www.logs.money/"><b>Logs</b></a> - a product suite to drive the shift towards frictionless money movement.<br /> 
            <br /> I've worked on fully on-chain casual games. Try it out <a href="https://t.me/BidTacToeBot"><b>here</b></a>!<br />  
            <br /> I'm broadly interested in infrastructures and applications that enable frictionless money movement, univeral market access, and new coordination mechanisms. <br /> 

            <br /> In my spare time, I enjoy playing bridge, reading, writing, tennis, and photography.<br />
            <br /> Once upon a time, I tried some modeling (in fashion, not on excel)<br />
            <br /> If things resonate with you, happy to connect and chat! My email is <a href="mailto:nicopei321@gmail.com">nicopei321@gmail.com</a> and <a href="https://twitter.com/sillyduckety">twitter</a> DM are open. Say hi!<br />
            <br />
            <b>"Excited about technologies that empower humanity to cooperate flexibly in large numbers"</b>
          </p>
  
          {/* Morse code button */}
          <p className="code" id="morse-code" onClick={toggleColor}>
            -.-- --- ..- .-. ... / - .-. ..- .-.. -.-- / -. .. -.-. ---
          </p>
  
  
          <p className="footage">
            <a href="mailto:nicopei321@gmail.com">email</a>
            <a href="https://twitter.com/sillyduckety">twitter</a>
            <a href="https://github.com/giffiecode">github</a>
            <a href="https://www.linkedin.com/in/nico-p-a2770a136/">linkedin</a>
            <a href="https://www.goodreads.com/user/show/66639895-nico-p">goodreads</a>
          </p>
        </div>
      </div>
    );
  } 

  export default AboutContent;